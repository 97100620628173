<template>
    <el-dialog
            title="新建模板"
            :close-on-click-modal="false"
            append-to-body
            :visible.sync="visible">
        <el-form size="small" :model="inputForm" :rules="dataRule" v-loading="loading"  ref="inputForm" @keyup.enter.native="doSubmit()"
                 label-width="120px" @submit.native.prevent>
            <el-form-item label="模板名：" prop="templateName">
                <el-input v-model="inputForm.templateName" placeholder="请输入模板名" maxlength="50"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="visible = false">取消</el-button>
      <el-button size="small" type="primary" @click="doSubmit()" v-noMoreClick>确定</el-button>
    </span>
    </el-dialog>
</template>

<script>
    export default {
        data () {
            return {
                visible: false,
                loading: false,
                inputForm: {
                    createUser:"",
                    templateName: '',
                    templateType:0,
                },
                dataRule: {
                    templateName: [
                        {required: true, message: '模板名不能为空', trigger: 'blur'}
                    ]
                },
                method:""
            }
        },
        methods: {
            init (id) {
                this.visible = true
                this.$nextTick(() => {
                    this.$refs['inputForm'].resetFields()
                })
                this.inputForm.createUser = id
                console.log(this.inputForm);
            },
            // 表单提交
            doSubmit () {
                this.$refs['inputForm'].validate((valid) => {
                    if (valid) {
                        this.loading = true
                        let api = this.api.mana.setTemplate
                        this.$axios(api, this.inputForm, 'post').then(data => {
                            this.loading = false
                            if (data && data.status) {
                                this.$message.success(data.msg)
                                this.visible = false
                                this.$emit('refreshDataList')
                            }
                        })
                    }
                })
            }
        }
    }
</script>
